<template>
  <main>
    <div v-if="!settings">
      <h1>Contact</h1>
      <h4 style="font-weight: normal;">If you have any questions or suggestions. then please write to us at <a href="mailto:guest@tourist.com">guest@tourist.com</a></h4>
    </div>
    <div v-else v-html="settings.contact">

    </div>
  </main>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";
import {mapState} from "vuex"
export default {
  name: "Contact",
  mixins:[titleMixin],
  computed:{
    ...mapState(['settings']),
  },
  title(){
    return 'Contact'
  },
}
</script>

<style scoped>

</style>
